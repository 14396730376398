import React, { useState, useEffect, useCallback } from "react";
import RoomItemsCard from "./RoomItemsCard";
import Skeleton from "@material-ui/lab/Skeleton";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { RoutesMap } from "./Routes";
import { Link as RouterLink } from "react-router-dom";
import ItemsCard from "./ItemsCard";
import RoomCard from "./RoomCard";
import MultiLineGridList from "./MultiLineGridList";
import SimilarRooms from "./SimilarRooms";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SimilarProductsSingleLine from "./SimilarProductsSingleLine";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import HoverCard from "./HoverCard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
}));

// TODO (delete productcard.js)
const ProductCard = ({ item }) => {
  const classes = useStyles();
  const theme = useTheme();

  const cardMediaStyle = {
    margin: "2px",
    height: item.height,
    width: item.width,
    backgroundColor: "#eee",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
  };

  const chipStyle = {
    margin: theme.spacing(0.5),
    fontSize: "16px",
    "&&:focus": {
      backgroundColor: "white",
    },
    "&&:hover": {
      borderColor: "black",
      backgroundColor: "white",
    },
  };

  const MyLink = (props) => (
    <RouterLink
      to={{
        pathname: item.productUrl,
      }}
      target="_blank"
      {...props}
    />
  );

  if (!item) {
    return (
      <div>
        <Skeleton variant="rect" style={cardMediaStyle} />
      </div>
    );
  } else {
    return (
      <Card
        className={classes.root}
        style={{ border: "none", boxShadow: "none" }}
      >
        <Grid container spacing={1}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            {/* <CardMedia image={item.src} style={cardMediaStyle} /> */}
            <HoverCard
              src={item.src}
              width={item.width}
              height={item.height}
            />
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography gutterBottom component="h5" variant="h5">
                  {item.productName}
                </Typography>
                <Typography gutterBottom variant="h6" component="h5">
                  {"$" + item.price}
                </Typography>
                <Typography
                  gutterBottom
                  variant="h6"
                  color="textSecondary"
                  component="h6"
                >
                  {item.merchant}
                </Typography>
                <Button variant="outlined" component={MyLink} style={chipStyle}>
                  Visit
                </Button>
              </CardContent>
            </div>
          </Grid>
        </Grid>
      </Card>
    );
  }
};

const Product = ({ match, trigger }) => {
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch("/api/product/" + match.params.product_id)
      .then((res) => res.json())
      .then(
        (result) => {
          const aspectRatio = result.width / result.height;
          if (aspectRatio < 1) {
            result.height = 400;
            result.width = result.height * aspectRatio;
          } else {
            result.width = 400;
            result.height = result.width / aspectRatio;
          }
          setProduct(result);
        },
        (error) => {}
      );
  }, [match.params.product_id]);

  return (
    <div>
      {/* TODO Skeleton should be from hovercard */}
      {!product && (
        <Skeleton variant="rect" style={{ width: 400, height: 400 }} />
      )}
      {product && <ProductCard item={product} />}

      <SimilarProductsSingleLine
        // key={match.params.product_id}
        product_id={match.params.product_id}
        title="Similar Items"
      />
      {product && (
        <SimilarRooms
          key={product.productID}
          title="Room Inspirations"
          product_id={product.productID}
          trigger={trigger}
        />
      )}
    </div>
  );
};

export default Product;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Slider from "react-slick";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ItemsCard from "./ItemsCard";
import Box from "@material-ui/core/Box";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import Hidden from "@material-ui/core/Hidden";

// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "block",
//     // flexWrap: "wrap",
//     // justifyContent: "space-around",
//     // overflow: "hidden",
//     // backgroundColor: theme.palette.background.paper,
//   },
//   gridList: {
//     flexWrap: "nowrap",
//     // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
//     transform: "translateZ(0)",
//   },
//   title: {
//     color: theme.palette.primary.light,
//   },
//   titleBar: {
//     background:
//       "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
//   },

//   arrowButtonPrev: {
//     zIndex: 1,
//     position: "absolute",
//     top: "50%",
//     left: "0%",
//     transform: "translate(-50%, -50%)",
//     backgroundColor: "#FFF",
//     "&:hover": {
//       backgroundColor: "#FFF",
//     },
//   },
//   arrowButtonNext: {
//     zIndex: 1,
//     position: "absolute",
//     top: "50%",
//     right: "0%",
//     transform: "translate(50%, -50%)",
//     backgroundColor: "#FFF",
//     "&:hover": {
//       backgroundColor: "#FFF",
//     },
//   },
// }));

// function SampleNextArrow(props) {
//   const { onClick, classes } = props;
//   return (
//     <IconButton onClick={onClick} className={classes.arrowButtonNext}>
//       <ArrowForward />
//     </IconButton>
//   );
// }

// function SamplePrevArrow(props) {
//   const { onClick, classes } = props;
//   return (
//     <IconButton onClick={onClick} className={classes.arrowButtonPrev}>
//       <ArrowBack />
//     </IconButton>
//   );
// }

// function RoomsSingleLineGridList(props) {
//   const classes = useStyles();
//   const settings = {
//     dots: false,
//     infinite: false,
//     slidesToShow: 4,
//     slidesToScroll: 4,
//     nextArrow: <SampleNextArrow classes={classes} />,
//     prevArrow: <SamplePrevArrow classes={classes} />,
//   };

//   return (
//     <Slider {...settings}>
//       {props.tileData.map((tile) => (
//         <Card>
//           <CardMedia
//             image={tile.src}
//             style={{
//               height: 300,
//             }}
//           />
//         </Card>
//       ))}
//     </Slider>
//   );
// }

// function ProductsSingleLineGridList(props) {
//   const classes = useStyles();
//   const settings = {
//     dots: false,
//     infinite: false,
//     slidesToShow: 3.5,
//     slidesToScroll: 3,
//     nextArrow: <SampleNextArrow classes={classes} />,
//     prevArrow: <SamplePrevArrow classes={classes} />,
//   };

//   const small_settings = {
//     ...settings,
//     slidesToShow: 2.333,
//     slidesToScroll: 2,
//     swipeToSlide: true,
//   };

//   return (
//     <div>
//       <Box display={{ xs: "block", md: "none" }}>
//         <Slider {...small_settings}>
//           {props.tileData.map((tile) => (
//             <ItemsCard item={tile} key={tile.productID} />
//           ))}
//         </Slider>
//       </Box>
//       <Box display={{ xs: "none", md: "block" }}>
//         <Slider {...settings}>
//           {props.tileData.map((tile) => (
//             <ItemsCard item={tile} key={tile.productID} />
//           ))}
//         </Slider>
//       </Box>
//     </div>
//   );
// }

// export { RoomsSingleLineGridList, ProductsSingleLineGridList };

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    // flexWrap: "wrap",
    // justifyContent: "space-around",
    // overflow: "hidden",
    // backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },

  arrowButtonPrev: {
    zIndex: 1,
    position: "absolute",
    top: "50%",
    left: "0%",
    transform: "translateX(-50%)",
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: "#FFF",
    },
    "&:disabled": {
      backgroundColor: "#FFF",
    },
  },
  arrowButtonNext: {
    zIndex: 1,
    position: "absolute",
    top: "50%",
    right: "0%",
    transform: "translateX(50%)",
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: "#FFF",
    },
    "&:disabled": {
      backgroundColor: "#FFF",
    },
  },
}));

function Cardd({ onClick, selected, title, itemId }) {
  const visibility = React.useContext(VisibilityContext);

  return (
    <div
      onClick={() => onClick(visibility)}
      style={{
        width: "160px",
      }}
      tabIndex={0}
    >
      <div className="card">
        <div>{title}</div>
        <div>visible: {JSON.stringify(!!visibility.isItemVisible(itemId))}</div>
        <div>selected: {JSON.stringify(!!selected)}</div>
      </div>
      <div
        style={{
          height: "200px",
        }}
      />
    </div>
  );
}

function LeftArrow(props) {
  const { isFirstItemVisible, scrollPrev } = React.useContext(
    VisibilityContext
  );
  console.log("left arrow here");
  const classes = useStyles();

  return (
    <IconButton
      disabled={isFirstItemVisible}
      onClick={() => scrollPrev()}
      className={classes.arrowButtonPrev}
    >
      <ArrowBack />
    </IconButton>
  );
}

function RightArrow(props) {
  const {
    isLastItemVisible,
    scrollNext,
    visibleItemsWithoutSeparators,
  } = React.useContext(VisibilityContext);
  const classes = useStyles();

  return (
    <IconButton
      disabled={isLastItemVisible}
      onClick={() => scrollNext()}
      className={classes.arrowButtonNext}
    >
      <ArrowForward />
    </IconButton>
  );
}

function SampleNextArrow(props) {
  const { onClick } = props;
  const classes = useStyles();
  return (
    <IconButton onClick={onClick} className={classes.arrowButtonNext}>
      <ArrowForward />
    </IconButton>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  const classes = useStyles();
  return (
    <IconButton onClick={onClick} className={classes.arrowButtonPrev}>
      <ArrowBack />
    </IconButton>
  );
}

function RoomsSingleLineGridList(props) {
  const classes = useStyles();
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <SampleNextArrow classes={classes} />,
    prevArrow: <SamplePrevArrow classes={classes} />,
  };

  return (
    <Slider {...settings}>
      {props.tileData.map((tile) => (
        <Card>
          <CardMedia
            image={tile.src}
            style={{
              height: 300,
            }}
          />
        </Card>
      ))}
    </Slider>
  );
}

function ProductsSingleLineGridList({ tileData }) {
  const classes = useStyles();

  return (
    <div style={{ position: "relative" }}>
      <Hidden smDown>
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {tileData.map((tile) => (
            <ItemsCard
              item={tile}
              key={tile.productID}
              itemId={tile.productID}
            />
          ))}
        </ScrollMenu>
      </Hidden>
      <Hidden mdUp>
        <ScrollMenu>
          {tileData.map((tile) => (
            <ItemsCard
              item={tile}
              key={tile.productID}
              itemId={tile.productID}
            />
          ))}
        </ScrollMenu>
      </Hidden>
    </div>
  );
}

function RoomItemsSingleLineGridList({ children }) {
  const classes = useStyles();

  return (
    <div style={{ position: "relative" }}>
      <Hidden smDown>
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
          {children}
        </ScrollMenu>
      </Hidden>
      <Hidden mdUp>
        <ScrollMenu>{children}</ScrollMenu>
      </Hidden>
    </div>
  );
}

export {
  RoomsSingleLineGridList,
  ProductsSingleLineGridList,
  RoomItemsSingleLineGridList,
};

import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import ProductCard from "./ProductCard";
import MultiLineGridList from "./MultiLineGridList";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import ItemsCard from "./ItemsCard";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  paper: {
    borderRadius: 20,
  },
  popper: {
    marginTop: theme.spacing(1.5),
    fontSize: "16px",
    zIndex: 2,
  },
  popperRoom: {
    marginTop: theme.spacing(3),
    fontSize: "16px",
    maxWidth: "700px",
    minWidth: "700px",
    maxHeight: "600px",
    minHeight: "600px",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  formButtons: {
    marginTop: theme.spacing(1.5),
  },
  avatar: {
    // margin: theme.spacing(1.5),
    // objectFit: 'cover',
    height: "80px",
    width: "80px",
  },
  selectedChip: {
    margin: theme.spacing(0.5),
    fontSize: "16px",
    backgroundColor: "black",
    color: "white",
    "&&:focus": {
      backgroundColor: "black",
    },
    "&&:hover": {
      backgroundColor: "black",
      borderColor: "black",
    },
  },
  notSelectedChip: {
    margin: theme.spacing(0.5),
    fontSize: "16px",
    "&&:focus": {
      backgroundColor: "white",
    },
    "&&:hover": {
      borderColor: "black",
      backgroundColor: "white",
    },
  },
  roomChip: {
    margin: theme.spacing(1),
    maxHeight: "50px",
    minHeight: "50px",
    fontSize: "24px",
    fontWeight: "bold",
    "&&:focus": {
      backgroundColor: "white",
    },
    "&&:hover": {
      borderColor: "black",
      backgroundColor: "white",
    },
  },
  roomButton: {
    textTransform: "none",
    fontSize: "20px",
    fontWeight: "bold",
    maxWidth: "200px",
    maxHeight: "200px",
    minWidth: "200px",
    minHeight: "200px",
  },
  cardMedia: {
    //   position: "absolute",
    // backgroundColor: "black",
    // display: "flex",
    // opacity: 0.1,
  },
  customBadge: {
    backgroundColor: "black",
    color: "white",
    fontSize: "15px",
  },
  filterItem: {
    margin: theme.spacing(3),
  },
}));

const Filters = ({
  categoryIndex,
  open,
  anchorEl,
  filters,
  handleChange,
  handleClear,
  handleApply,
  isLoaded,
}) => {
  const classes = useStyles();
  //const handleChange = (event) => {
  //setFilter({ ...filter, [event.target.name]: event.target.checked });
  //};

  //const handleClear = (event) => {
  // setFilter(
  //   Object.fromEntries(Object.keys(filter).map((key) => [key, false]))
  // );
  //};

  return (
    <Popper
      className={classes.popper}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      disablePortal={true}
    >
      <Paper className={classes.paper}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <div style={{ maxHeight: 500, overflow: "auto" }}>
              {filters.map((filter, i) => {
                return (
                  <div
                    style={{
                      display: "flex",
                    }}
                    key={filter.name}
                  >
                    {filter.color ? (
                      <Badge
                        className={classes.filterItem}
                        classes={{ badge: classes.customBadge }}
                        badgeContent={filter.count}
                        max={1000}
                        color="primary"
                      >
                        <Avatar
                          variant="rounded"
                          className={classes.avatar}
                          style={{ backgroundColor: filter.color }}
                        >
                          {" "}
                        </Avatar>
                      </Badge>
                    ) : (
                      <Badge
                        className={classes.filterItem}
                        classes={{ badge: classes.customBadge }}
                        badgeContent={filter.count}
                        max={1000}
                        color="primary"
                      >
                        <Avatar
                          variant="rounded"
                          className={classes.avatar}
                          src={filter.icon}
                        ></Avatar>
                      </Badge>
                    )}
                    <FormControlLabel
                      disabled={!isLoaded}
                      control={
                        <Checkbox
                          color="default"
                          checked={filter.selected}
                          onChange={handleChange(categoryIndex, i)}
                          name={filter.name}
                        />
                      }
                      label={filter.label}
                      key={filter.name}
                    />
                  </div>
                );
              })}
            </div>
          </FormGroup>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Button
              className={classes.formButtons}
              onClick={handleClear(categoryIndex)}
            >
              Clear
            </Button>
            <Button className={classes.formButtons} onClick={handleApply}>
              Done
            </Button>
          </div>
        </FormControl>
      </Paper>
    </Popper>
  );
};

export default Filters;

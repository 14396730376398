import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

//import Rooms from "./Rooms";
import Products from "./Products2";
import Home from "./Home";
import RoomItems from "./RoomItems";
import Product from "./Product";
import Search from "./Search";

import Rooms from "./Rooms2";

const Routes = ({ trigger, setSearchText }) => {
  const location = useLocation();
  useEffect(() => {
    setSearchText("");
  }, [location]);

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/Rooms/:category" render={(props) => <Rooms {...props} />} />
      <Route path="/Rooms" render={(props) => <Rooms trigger={trigger} />} />
      {/* TODO change RoomItems to Room */}
      <Route
        path="/RoomItems/:room_id"
        render={(props) => <RoomItems match={props.match} trigger={trigger} />}
      />
      <Route
        path="/Products/:category"
        render={(props) => <Products match={props.match} trigger={trigger} />}
      />
      <Route
        path="/Products"
        render={(props) => <Products match={props.match} trigger={trigger} />}
      />
      <Route
        path="/Product/:product_id"
        render={(props) => <Product match={props.match} trigger={trigger} />}
      />
      <Route
        path="/search/:query"
        render={(props) => <Search match={props.match} trigger={trigger} />}
      />
    </Switch>
  );
};

export default Routes;

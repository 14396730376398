import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import RoomItemsCard from "./RoomItemsCard";
import MultiLineGridList from "./MultiLineGridList2";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Filters from "./Filters2";
import Box from "@material-ui/core/Box";
import FilterChip from "./FilterChip";
import FilterRow from "./FilterRow";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  paper: {
    borderRadius: 20,
  },
  popper: {
    marginTop: theme.spacing(1.5),
    fontSize: "16px",
  },
  popperRoom: {
    marginTop: theme.spacing(3),
    fontSize: "16px",
    maxWidth: "700px",
    minWidth: "700px",
    maxHeight: "600px",
    minHeight: "600px",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  formButtons: {
    marginTop: theme.spacing(1.5),
  },
  avatar: {
    margin: theme.spacing(1.5),
    height: "100px",
    width: "100px",
  },
  selectedChip: {
    // margin: theme.spacing(0.5),
    fontSize: "16px",
    backgroundColor: "black",
    color: "white",
    "&&:focus": {
      backgroundColor: "black",
    },
    "&&:hover": {
      backgroundColor: "black",
      borderColor: "black",
    },
  },
  notSelectedChip: {
    // margin: theme.spacing(0.5),
    fontSize: "16px",
    "&&:focus": {
      backgroundColor: "white",
    },
    "&&:hover": {
      borderColor: "black",
      backgroundColor: "white",
    },
  },
  roomChip: {
    margin: theme.spacing(1),
    maxHeight: "50px",
    minHeight: "50px",
    fontSize: "24px",
    fontWeight: "bold",
    "&&:focus": {
      backgroundColor: "white",
    },
    "&&:hover": {
      borderColor: "black",
      backgroundColor: "white",
    },
  },
  roomButton: {
    textTransform: "none",
    fontSize: "20px",
    fontWeight: "bold",
    maxWidth: "200px",
    maxHeight: "200px",
    minWidth: "200px",
    minHeight: "200px",
  },
  cardMedia: {
    //   position: "absolute",
    // backgroundColor: "black",
    // display: "flex",
    // opacity: 0.1,
  },
  filterSkeletonRow: {
    display: "flex",
  },
  filterSkeleton: {
    // margin: "4px",
    // padding: "4px",
    borderRadius: "100px",
  },
  filterSkeleton2: {
    // margin: "4px",
    // padding: "4px",
    margin: theme.spacing(0.5),
    borderRadius: "100px",
  },
  stickyPaper: {
    background: "white",
    position: "-webkit-sticky",
    position: "sticky",
    top: 0,
    bottom: 0,
    paddingTop: "20px",
    paddingBottom: "20px",
    zIndex: 2,
  },
  nonStickyPaper: {
    background: "white",
    top: 0,
    bottom: 0,
    paddingTop: "20px",
    paddingBottom: "20px",
    zIndex: 2,
  },
}));

const RoomGallery = ({ title, endpoint, productId, trigger }) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const [newFilters, setNewFilters] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [currFilter, setCurrFilter] = React.useState();
  const [hasMore, setHasMore] = React.useState(true);

  const [from, setFrom] = React.useState(0);
  const size = 50;

  const handleFilterChipClick = (newFilter) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => currFilter !== newFilter || !prev);
    setCurrFilter(newFilter);
    console.info("You clicked the filter Chip");
  };

  const handleFilterChange = (categoryIndex, optionsIndex) => (event) => {
    const newArr = [...newFilters];
    newArr[categoryIndex].options[optionsIndex].selected = event.target.checked;
    setNewFilters(newArr);
    setIsLoaded(false);
  };

  const handleClear = (categoryIndex) => (event) => {
    const newArr = [...newFilters];
    if (
      newArr[categoryIndex].options.filter((opt) => opt.selected).length > 0
    ) {
      newArr[categoryIndex].options.map((a) => (a.selected = false));
      setNewFilters(newArr);
      setIsLoaded(false);
    }
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleApply = () => {
    setOpen(false);
  };

  const filterOptions = (filter, func) => ({
    category: filter.category,
    options: filter.options.filter(func),
  });
  // TODO sort fields without rebuilding entire object
  const sortCountsByName = (counter) => ({
    category: counter.category,
    label: counter.label,
    counts: counter.counts.sort((a, b) => b.count - a.count),
  });

  const mapCountsToOptions = (counter, func) => ({
    category: counter.category,
    label: counter.label,
    options: counter.counts.map(func),
  });

  const defaultFilterFromCount = (counter) =>
    mapCountsToOptions(counter, (count) => ({
      name: count.name,
      // TODO: below has no effect
      label: count.label,
      selected: false,
    }));

  const mergeCountersWithFilters = (counters, filters) =>
    counters
      .sort((a, b) => a.category.localeCompare(b.category))
      .map(
        (counter) =>
          filters.find((filter) => filter.category === counter.category) ||
          defaultFilterFromCount(counter)
      )
      .map((filter, i) =>
        mapCountsToOptions(sortCountsByName(counters[i]), (count) => ({
          ...filter.options.find((opt) => opt.name === count.name),
          ...count,
        }))
      );

  const query = newFilters
    .map((filter) => filterOptions(filter, (f) => f.selected))
    .filter((filter) => filter.options.length > 0)
    .map(
      (filter) =>
        "or." +
        filter.category +
        "=" +
        filter.options
          .map((option) => "eq." + encodeURIComponent(option.name))
          .join(",")
    )
    .join("&");

  useEffect(() => {
    console.log("fetching counts 2");
    fetch(
      [
        endpoint + "?",
        query,
        productId ? productId : "",
        "from=" + 0,
        "size=" + size,
        "aggs=" + true,
      ]
        .filter((f) => f.length > 0)
        .join("&")
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("counts are", result);
          setNewFilters(mergeCountersWithFilters(result.counters, newFilters));
          if (result.rooms) {
            setItems([result.rooms]);
            setFrom(size);

            if (result.rooms.length != size) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          } else {
            setItems([]);
            setHasMore(false);
          }
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [query]);

  const fetchMoreData = () => {
    fetch(
      [
        endpoint + "?",
        query,
        productId ? productId : "",
        "from=" + from,
        "size=" + size,
      ]
        .filter((f) => f.length > 0)
        .join("&")
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.rooms) {
            setItems(items.concat([result.rooms]));
          }

          setFrom(from + size);

          if (result.rooms.length != size) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setError(error);
        }
      );
  };

  return (
    <div>
      <Paper
        className={trigger ? classes.stickyPaper : classes.nonStickyPaper}
        elevation={0}
      >
        <Typography
          variant="h5"
          style={{ padding: "15px", fontWeight: "bold" }}
        >
          {title}
        </Typography>

        <FilterRow
          filters={newFilters}
          currFilter={currFilter}
          isLoaded={isLoaded}
          open={open}
          anchorEl={anchorEl}
          handleClickAway={handleClickAway}
          handleFilterChipClick={handleFilterChipClick}
          handleFilterChange={handleFilterChange}
          handleClear={handleClear}
          handleApply={handleApply}
        />
        {/* {newFilters.length > 0 ? (
          <Paper component="ul" className={classes.root} elevation={0}>
            <ClickAwayListener onClickAway={handleClickAway}>
              <div style={{ display: "flex" }}>
                {newFilters.map((data, i) => {
                  return (
                    <li key={data.category} style={{ padding: "4px" }}>
                      {Object.values(data.options).some(
                        (val) => val.selected === true
                      ) ? (
                        <FilterChip
                          // variant="outlined"
                          key={data.category}
                          isLoaded={isLoaded}
                          category={data.category}
                          currFilter={currFilter}
                          label={data.label}
                          className={classes.selectedChip}
                          skeletonClass={classes.filterSkeleton}
                          onClick={handleFilterChipClick(data.category)}
                        />
                      ) : (
                        <FilterChip
                          key={data.category}
                          isLoaded={isLoaded}
                          category={data.category}
                          currFilter={currFilter}
                          variant="outlined"
                          label={data.label}
                          className={classes.notSelectedChip}
                          skeletonClass={classes.filterSkeleton}
                          onClick={handleFilterChipClick(data.category)}
                        />
                      )}

                      {data.category == currFilter && (
                        <Filters
                          isLoaded={isLoaded}
                          open={open}
                          anchorEl={anchorEl}
                          filters={data.options}
                          categoryIndex={i}
                          handleChange={handleFilterChange}
                          handleClear={handleClear}
                          handleApply={handleApply}
                        />
                      )}
                    </li>
                  );
                })}
              </div>
            </ClickAwayListener>
          </Paper>
        ) : (
          <div className={classes.filterSkeletonRow}>
            {[...Array(3)].map((_, i) => (
              <Skeleton
                key={i}
                className={classes.filterSkeleton2}
                variant="rect"
                width={60}
                height={30}
              />
            ))}
          </div>
        )} */}
      </Paper>

      <RoomsInner
        srcs={items}
        hasMore={hasMore}
        fetchMoreData={fetchMoreData}
        isLoaded={isLoaded}
      />
    </div>
  );
};

const RoomsInner = ({ srcs, hasMore, fetchMoreData, isLoaded }) => {
  const handleChipClick = () => {};

  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => (
      <RoomItemsCard
        imageId={photo.imageId}
        key={photo.imageId}
        src={photo.src}
        width={photo.width}
        height={photo.height}
        handleChipClick={handleChipClick}
      />
    ),
    [srcs]
  );

  const imageRendererNotLoaded = useCallback(
    ({ index, left, top, key, photo }) => (
      <div key={photo.imageId} style={{ margin: "2px", padding: "2px" }}>
        <Skeleton variant="rect">
          <Box width={photo.width - 4} height={photo.height - 4} />
        </Skeleton>
      </div>
    ),
    []
  );

  if (!isLoaded) {
    return (
      <div>
        <MultiLineGridList
          srcs={srcs}
          fetchMoreData={fetchMoreData}
          imageRenderer={imageRendererNotLoaded}
          hasMore={hasMore}
        />
      </div>
    );
  } else {
    return (
      <div>
        <MultiLineGridList
          srcs={srcs}
          fetchMoreData={fetchMoreData}
          imageRenderer={imageRenderer}
          hasMore={hasMore}
        />
      </div>
    );
  }
};

export default RoomGallery;

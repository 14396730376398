import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@material-ui/core/CircularProgress";
import Gallery from "react-photo-gallery";

const useStyles = makeStyles((theme) => ({
  loader: {
    textAlign: "center",
    padding: "10px",
    color: "black",
  },
}));

const MultiLineGridList = ({
  title,
  srcs,
  fetchMoreData,
  imageRenderer,
  hasMore,
}) => {
  const classes = useStyles();

  return (
    <div>
      <h2> {title} </h2>
      <InfiniteScroll
        dataLength={srcs.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <div className={classes.loader}>
            <CircularProgress color="inherit" />
          </div>
        }
        scrollThreshold={0.8}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Fin</b>
          </p>
        }
      >
        <div>
          {srcs.map((src, i) => (
            <Gallery key={i} photos={src} renderImage={imageRenderer} />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default MultiLineGridList;

import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import RoomItemsCard from "./RoomItemsCard";
import MultiLineGridList from "./MultiLineGridList2";
import RangeFilter from "./RangeFilter";
import FilterChip from "./FilterChip";
import FilterRow from "./FilterRow";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Filters from "./Filters2";
import Box from "@material-ui/core/Box";
import ItemsCard from "./ItemsCard";
import Slider from "@material-ui/core/Slider";
import { SignalWifi1BarLockSharp } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import Slide from "@material-ui/core/Slide";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import AppBar from "@material-ui/core/AppBar";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  paper: {
    borderRadius: 20,
  },
  popper: {
    marginTop: theme.spacing(1.5),
    fontSize: "16px",
  },
  popperRoom: {
    marginTop: theme.spacing(3),
    fontSize: "16px",
    maxWidth: "700px",
    minWidth: "700px",
    maxHeight: "600px",
    minHeight: "600px",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  formButtons: {
    marginTop: theme.spacing(1.5),
  },
  avatar: {
    margin: theme.spacing(1.5),
    height: "100px",
    width: "100px",
  },
  selectedChip: {
    // margin: theme.spacing(0.5),
    fontSize: "16px",
    backgroundColor: "black",
    color: "white",
    "&&:focus": {
      backgroundColor: "black",
    },
    "&&:hover": {
      backgroundColor: "black",
      borderColor: "black",
    },
  },
  notSelectedChip: {
    // margin: theme.spacing(0.5),
    fontSize: "16px",
    "&&:focus": {
      backgroundColor: "white",
    },
    "&&:hover": {
      borderColor: "black",
      backgroundColor: "white",
    },
  },
  roomChip: {
    margin: theme.spacing(1),
    maxHeight: "50px",
    minHeight: "50px",
    fontSize: "24px",
    fontWeight: "bold",
    "&&:focus": {
      backgroundColor: "white",
    },
    "&&:hover": {
      borderColor: "black",
      backgroundColor: "white",
    },
  },
  roomButton: {
    textTransform: "none",
    fontSize: "20px",
    fontWeight: "bold",
    maxWidth: "200px",
    maxHeight: "200px",
    minWidth: "200px",
    minHeight: "200px",
  },
  cardMedia: {
    //   position: "absolute",
    // backgroundColor: "black",
    // display: "flex",
    // opacity: 0.1,
  },
  filterSkeletonRow: {
    display: "flex",
  },
  filterSkeleton: {
    // margin: "4px",
    // padding: "4px",
    borderRadius: "100px",
  },
  filterSkeleton2: {
    // margin: "4px",
    // padding: "4px",
    margin: theme.spacing(0.5),
    borderRadius: "100px",
  },
  slider: {
    display: "flex",
    // margin: "4px",
    // padding: "4px",
    padding: theme.spacing(1),
    color: "black",
  },
  popperSlider: {
    width: "300px",
    marginTop: theme.spacing(1.5),
    fontSize: "16px",
  },
  popperMargin: {
    padding: theme.spacing(3),
  },
  priceField: {
    marginRight: theme.spacing(2.7),
    marginLeft: theme.spacing(2.7),
    marginTop: theme.spacing(1.5),
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
  },
  stickyPaper: {
    background: "white",
    position: "-webkit-sticky",
    position: "sticky",
    top: 0,
    bottom: 0,
    paddingTop: "20px",
    paddingBottom: "20px",
    zIndex: 2,
  },
  nonStickyPaper: {
    background: "white",
    top: 0,
    bottom: 0,
    paddingTop: "20px",
    paddingBottom: "20px",
    zIndex: 2,
  },
}));

function HideOnScroll(props) {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Spacer = ({}) => {
  return (
    <div>
      <Hidden smDown>
        <Toolbar />
      </Hidden>
      <Hidden mdUp>
        <Toolbar />
        <Toolbar />
      </Hidden>
    </div>
  );
};

const ProductGallery = ({
  title,
  endpoint,
  hiddenFilter,
  matchQuery,
  bboxId,
  trigger,
}) => {
  const classes = useStyles();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const [newFilters, setNewFilters] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [currFilter, setCurrFilter] = React.useState();
  const [from, setFrom] = React.useState(0);
  const size = 50;
  const [hasMore, setHasMore] = React.useState(true);

  const [rangeFilter, setRangeFilter] = React.useState([
    {
      category: "price",
      range: [
        {
          value: null,
          op: "gt",
        },
        {
          value: null,
          op: "lt",
        },
      ],
    },
  ]);

  const handleRangeApply = (range) => (event) => {
    setOpen(false);
    setIsLoaded(false);
    setRangeFilter([
      {
        category: "price",
        range: [
          {
            value: range[0],
            op: "gt",
          },
          {
            value: range[1],
            op: "lt",
          },
        ],
      },
    ]);
  };

  const handleFilterChipClick = (newFilter) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => currFilter !== newFilter || !prev);
    setCurrFilter(newFilter);
    // setOpen(true);
    console.info("You clicked the filter Chip");
  };

  const handleFilterChange = (categoryIndex, optionsIndex) => (event) => {
    const newArr = [...newFilters];
    newArr[categoryIndex].options[optionsIndex].selected = event.target.checked;
    setNewFilters(newArr);
    setIsLoaded(false);
  };

  const handleClear = (categoryIndex) => (event) => {
    const newArr = [...newFilters];
    if (
      newArr[categoryIndex].options.filter((opt) => opt.selected).length > 0
    ) {
      newArr[categoryIndex].options.map((a) => (a.selected = false));
      setNewFilters(newArr);
      setIsLoaded(false);
    }
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleApply = () => {
    setOpen(false);
  };

  const filterOptions = (filter, func) => ({
    category: filter.category,
    options: filter.options.filter(func),
  });

  const sortCountsByName = (counter) => ({
    category: counter.category,
    label: counter.label,
    counts: counter.counts.sort((a, b) => b.count - a.count),
  });

  const mapCountsToOptions = (counter, func) => ({
    category: counter.category,
    label: counter.label,
    options: counter.counts.map(func),
  });

  const defaultFilterFromCount = (counter) =>
    mapCountsToOptions(counter, (count) => ({
      name: count.name,
      label: count.label,
      selected: false,
    }));

  const mergeCountersWithFilters = (counters, filters) =>
    counters
      .sort((a, b) => a.category.localeCompare(b.category))
      .map(
        (counter) =>
          filters.find((filter) => filter.category === counter.category) ||
          defaultFilterFromCount(counter)
      )
      .map((filter, i) =>
        mapCountsToOptions(sortCountsByName(counters[i]), (count) => ({
          ...filter.options.find((opt) => opt.name === count.name),
          ...count,
        }))
      );

  const combinedFilters = hiddenFilter
    ? [...newFilters, hiddenFilter]
    : newFilters;

  const filterQuery = combinedFilters
    .map((filter) => filterOptions(filter, (f) => f.selected))
    .map((filter) => filterOptions(filter, (f) => f.name))
    .filter((filter) => filter.options.length > 0)
    .map(
      (filter) =>
        "or." +
        filter.category +
        "=" +
        filter.options
          .map((option) => "eq." + encodeURIComponent(option.name))
          .join(",")
    )
    .join("&");

  const rangeQuery = rangeFilter
    .filter((f) => f.range[0].value != null || f.range[1].value != null)
    .map(
      (item, i) =>
        "and.price=" +
        item.range
          .filter((item) => item.value)
          .map((range, i) => range.op + "." + range.value)
          .join(",")
    )
    .join("&");

  useEffect(() => {
    console.log("fetching counts");
    fetch(
      [
        endpoint + "?",
        filterQuery,
        rangeQuery,
        matchQuery ? matchQuery : "",
        bboxId ? bboxId : "",
        "from=" + 0,
        "size=" + size,
        "aggs=" + true,
      ]
        .filter((f) => f.length > 0)
        .join("&")
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log("counts are", result, result.counters.length);
          setNewFilters(mergeCountersWithFilters(result.counters, newFilters));
          if (result.items) {
            setItems([result.items]);
            setFrom(size);

            if (result.items.length != size) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
          } else {
            setItems([]);
            setHasMore(false);
            setFrom(0);
          }
          setIsLoaded(true);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [filterQuery, rangeQuery]);

  const fetchMoreData = () => {
    fetch(
      endpoint +
        "?" +
        [
          filterQuery,
          rangeQuery,
          matchQuery ? matchQuery : "",
          bboxId ? bboxId : "",
          "from=" + from,
          "size=" + size,
        ]
          .filter((f) => f.length > 0)
          .join("&")
    )
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.items) {
            setItems(items.concat([result.items]));
          }

          setFrom(from + size);

          if (result.items.length != size) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setError(error);
        }
      );
  };

  return (
    <div>
      <Paper
        className={trigger ? classes.stickyPaper : classes.nonStickyPaper}
        elevation={0}
      >
        <Typography
          variant="h5"
          style={{ padding: "15px", fontWeight: "bold" }}
        >
          {title}
        </Typography>
        <FilterRow
          filters={newFilters}
          rangeFilter={rangeFilter}
          currFilter={currFilter}
          isLoaded={isLoaded}
          open={open}
          anchorEl={anchorEl}
          handleClickAway={handleClickAway}
          handleFilterChipClick={handleFilterChipClick}
          handleFilterChange={handleFilterChange}
          handleClear={handleClear}
          handleApply={handleApply}
          handleRangeApply={handleRangeApply}
        />
      </Paper>

      <ProductsInner
        srcs={items}
        hasMore={hasMore}
        fetchMoreData={fetchMoreData}
        isLoaded={isLoaded}
      />
    </div>
  );
};

const ProductsInner = ({ srcs, hasMore, fetchMoreData, isLoaded }) => {
  const imageRenderer = useCallback(
    ({ index, left, top, key, photo }) => (
      <ItemsCard
        item={photo}
        key={photo.productID}
        src={photo.src}
        width={photo.width}
        height={photo.height}
      />
    ),
    []
  );

  const imageRendererNotLoaded = useCallback(
    ({ index, left, top, key, photo }) => (
      <div key={photo.productID} style={{ margin: "2px", padding: "2px" }}>
        <Skeleton variant="rect">
          <Box width={photo.width - 4} height={photo.height - 4} />
        </Skeleton>
      </div>
    ),
    []
  );

  if (!isLoaded) {
    return (
      <div>
        <MultiLineGridList
          srcs={srcs}
          fetchMoreData={fetchMoreData}
          imageRenderer={imageRendererNotLoaded}
          hasMore={hasMore}
        />
      </div>
    );
  } else {
    return (
      <div>
        <MultiLineGridList
          srcs={srcs}
          fetchMoreData={fetchMoreData}
          imageRenderer={imageRenderer}
          hasMore={hasMore}
        />
      </div>
    );
  }
};

export default ProductGallery;

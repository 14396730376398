import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import ItemsCard from "./ItemsCard";
import MultiLineGridList from "./MultiLineGridList2";
import RoomCard from "./RoomCard";
import RoomGallery from "./RoomGallery";

// function SimilarRooms(props) {
//   const [error, setError] = useState(null);
//   const [rooms, setRooms] = useState([]);
//   const [hasMore, setHasMore] = React.useState(true);
//   const [from, setFrom] = React.useState(0);
//   const size = 50;
//   const [isLoaded, setIsLoaded] = useState(false);

//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const [open, setOpen] = React.useState(false);
//   const [currFilter, setCurrFilter] = React.useState();

//   const imageRenderer = useCallback(
//     ({ index, left, top, key, photo }) => (
//       <RoomCard
//         imageId={photo.imageId}
//         key={key}
//         src={photo.src}
//         width={photo.width}
//         height={photo.height}
//       />
//     ),
//     [rooms]
//   );
//   const query = "?";
//   // Note: the empty deps array [] means
//   // this useEffect will run once
//   // similar to componentDidMount()
//   console.log(
//     "productid",
//     "api/product-rooms?product-id=" +
//       props.productID +
//       "&from=" +
//       0 +
//       "&size=" +
//       size
//   );
//   useEffect(() => {
//     fetch(
//       "/api/product-rooms?product-id=" +
//         props.productID +
//         "&from=" +
//         0 +
//         "&size=" +
//         size
//     )
//       .then((res) => res.json())
//       .then(
//         (result) => {
//           if (result.rooms) {
//             setRooms([result.rooms]);
//             setFrom(size);

//             if (result.rooms.length != size) {
//               setHasMore(false);
//             } else {
//               setHasMore(true);
//             }
//           } else {
//             setRooms([]);
//             setHasMore(false);
//             setFrom(0);
//           }
//           setIsLoaded(true);
//         },
//         // Note: it's important to handle errors here
//         // instead of a catch() block so that we don't swallow
//         // exceptions from actual bugs in components.
//         (error) => {
//           setError(error);
//           setIsLoaded(true);
//         }
//       );
//   }, [props.productID]);

//   const fetchMoreData = () => {
//     fetch(
//       "/api/product-rooms?product-id=" +
//         props.productID +
//         "&from=" +
//         from +
//         "&size=" +
//         size
//     )
//       .then((res) => res.json())
//       .then(
//         (result) => {
//           if (result.rooms) {
//             setRooms(rooms.concat([result.rooms]));
//           }
//           setFrom(from + size);

//           if (result.rooms.length != size) {
//             setHasMore(false);
//           } else {
//             setHasMore(true);
//           }
//         },
//         // Note: it's important to handle errors here
//         // instead of a catch() block so that we don't swallow
//         // exceptions from actual bugs in components.
//         (error) => {
//           setError(error);
//         }
//       );
//   };

//   if (error) {
//     return <div> Error: {error.message} </div>;
//   } else {
//     return (
//       <MultiLineGridList
//         title={props.title}
//         srcs={rooms}
//         fetchMoreData={fetchMoreData}
//         imageRenderer={imageRenderer}
//         hasMore={hasMore}
//       />
//     );
//   }
// }

const SimilarRooms = ({ product_id, title, trigger }) => {
  console.log("loading similar rooms");
  return (
    <RoomGallery
      title={title}
      endpoint={"/api/product-rooms"}
      productId={`product-id=${product_id}`}
      trigger={trigger}
    />
  );
};

export default SimilarRooms;

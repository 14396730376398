import React, { useRef, useEffect } from "react";

const Canvas = ({ src, item, height, width }) => {
  const canvasRef = useRef(null);
  const bbox_x = item.xRatio * width;
  const bbox_y = item.yRatio * height;
  const bbox_width = item.widthRatio * width;
  const bbox_height = item.heightRatio * height;

  var scaled_height;
  var scaled_width;

  const aspectRatio = bbox_width / bbox_height;
  if (aspectRatio < 1) {
    scaled_height = 50;
    scaled_width = scaled_height * aspectRatio;
  } else {
    scaled_width = 50;
    scaled_height = scaled_width / aspectRatio;
  }

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = src;

    ctx.drawImage(
      image,
      bbox_x,
      bbox_y,
      bbox_width,
      bbox_height,
      0,
      0,
      scaled_width,
      scaled_height
    );

    //Our draw come here
    // draw(context)
  }, [
    src,
    bbox_x,
    bbox_y,
    bbox_width,
    bbox_height,
    scaled_width,
    scaled_height,
  ]);

  return <canvas ref={canvasRef} width={scaled_width} height={scaled_height} />;
};

export default Canvas;

import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import HoverCard from "./HoverCard";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Canvas from "./Canvas";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import { RoomItemsSingleLineGridList } from "./SingleLineGridList";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  stickyPaper: {
    background: "white",
    position: "-webkit-sticky",
    position: "sticky",
    top: 0,
    // bottom: 0,
    paddingTop: "20px",
    paddingBottom: "20px",
    zIndex: 1,
  },
  nonStickyPaper: {
    background: "white",
    top: 0,
    // bottom: 0,
    paddingTop: "20px",
    paddingBottom: "20px",
    zIndex: 1,
  },
}));

const SimplePopover = ({ src, items, bbox_id, width, height, handleClick }) => {
  const classes = useStyles();

  const selected_item = items.filter((e) => e.bboxId === bbox_id);

  return (
    <div>
      {selected_item.length > 0 && (
        <div>
          <RoomItemsSingleLineGridList
            children={items.map((item, i) => (
              <Box itemId={item.bboxId} display="inline-block" padding="10px">
                {selected_item[0].bboxId == item.bboxId ? (
                  <Paper
                    variant="outlined"
                    style={{
                      borderRadius: 20,
                      backgroundColor: "white",
                      borderColor: "black",
                      borderWidth: "2px",
                    }}
                  >
                    <Card
                      elevation={0}
                      style={{ borderRadius: 20, backgroundColor: "white" }}
                    >
                      <CardActionArea>
                        <CardContent>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Canvas
                              src={src}
                              item={item}
                              height={height}
                              width={width}
                            />
                            <Typography
                              className={classes.typography}
                              style={{ color: "black" }}
                            >
                              {item.label}
                            </Typography>
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Paper>
                ) : (
                  <Paper
                    variant="outlined"
                    style={{ borderRadius: 20, backgroundColor: "white" }}
                  >
                    <Card
                      elevation={0}
                      style={{ borderRadius: 20, backgroundColor: "white" }}
                    >
                      <CardActionArea
                        onClick={() => {
                          handleClick(item);
                        }}
                      >
                        <CardContent>
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Canvas
                              src={src}
                              item={item}
                              height={height}
                              width={width}
                            />
                            <Typography
                              className={classes.typography}
                              style={{ color: "black" }}
                            >
                              {item.label}
                            </Typography>
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Paper>
                )}
              </Box>
            ))}
          />
        </div>
      )}
    </div>
  );
};

// TODO split this class into two to isolate popover case.
const RoomItemsCard = ({
  imageId,
  src,
  width,
  height,
  handleChipClick,
  bbox_id,
  popOver = false,
  trigger,
}) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  var scaled_height = height;
  var scaled_width = width;

  if (popOver) {
    const aspectRatio = width / height;
    if (aspectRatio < 1) {
      scaled_height = 400;
      scaled_width = scaled_height * aspectRatio;
    } else {
      scaled_width = 400;
      scaled_height = scaled_width / aspectRatio;
    }
  }

  const classes = useStyles();

  useEffect(() => {
    // TODO, make this endpoint not need to read rooms
    fetch("/api/bboxes?room_id=" + imageId)
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log("/api/bboxes?room_id=" + imageId, result)
          // setIsLoaded(true);

          if (result == null) {
            console.log("bug here", result, imageId);
          }
          if (result != null && result.length > 0) {
            setItems(result);
            handleChipClick(result[0]);
          }
        },
        (error) => {
          console.log(imageId, "error");
          setIsLoaded(true);
          setError(error);
        }
      );
  }, [imageId]);

  if (error) {
    return <div> Error: {error.message} </div>;
  } else {
    return (
      <div>
        <RouterLink
          to={{
            pathname: `/RoomItems/${imageId}`,
          }}
        >
          <div style={{ display: "flex" }}>
            <HoverCard
              items={items}
              src={src}
              width={scaled_width}
              height={scaled_height}
              handleChipClick={handleChipClick}
            />
          </div>
        </RouterLink>
        {popOver && items.length > 0 && (
          <div>
            <Paper
              className={trigger ? classes.stickyPaper : classes.nonStickyPaper}
              elevation={0}
            >
              <h2> In This Room </h2>
              <SimplePopover
                src={src}
                items={items}
                bbox_id={bbox_id}
                width={width}
                height={height}
                handleClick={handleChipClick}
              />
            </Paper>
          </div>
        )}
      </div>
    );
  }
};

export default RoomItemsCard;

import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Favorite from "@material-ui/icons/Favorite";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Tooltip from "@material-ui/core/Tooltip";
import Skeleton from "@material-ui/lab/Skeleton";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // flexWrap: 'wrap',
    // justifyContent: 'space-around',
    // overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // display: 'flex',
    // justifyContent: 'center',
    //     display: 'inline-block',
    // display: 'flex',
    // width: 500,
    //     flexWrap: 'wrap',
    //    height: 200,
  },
  gridListTile: {
    // display: 'flex',
    // justifyContent: 'center',
    //     display: 'inline-block',
    // width: 120,
    // height: 200,
  },
  media: {
    // display: 'flex',
    // display: 'inline-block',
    // flexWrap: 'wrap',
  },
  card: {
    // display: 'flex',
    // height: 600
    // height: 'inherit'
  },
  overlay: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    color: "black",
    backgroundColor: "white",
  },
}));

const HoverCard = ({
  src,
  width,
  height,
  items,
  handleChipClick,
  cardContent,
  cardActions,
}) => {
  const classes = useStyles();
  const [buttonStyle, setButtonStyle] = useState({
    display: "none",
  });

  const cardStyle = {
    margin: "2px",
    height: height,
    width: width,
    backgroundColor: "#eee",
    cursor: "pointer",
    overflow: "hidden",
    position: "relative",
  };

  const onMouseOver = () => {
    const style = {
      display: "flex",
    };
    setButtonStyle(style);
  };

  const onMouseOut = () => {
    const style = {
      display: "none",
    };
    setButtonStyle(style);
  };

  const [liked, setLiked] = React.useState(false);

  if (!src) {
    return (
      <div>
        <Skeleton variant="rect" style={cardStyle} />
      </div>
    );
  } else {
    return (
      <Card
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        style={{ border: "none", boxShadow: "none" }}
      >
        <CardActionArea>
          {/*<CardActionArea component={RouterLink} to={to}>*/}
          <div>
            <CardMedia style={cardStyle} image={src} />

            <IconButton
              onMouseDown={(event) => event.stopPropagation()}
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                console.log("Button liked");
                setLiked(!liked);
              }}
              style={{
                backgroundColor: "#FFF",
                "&:hover": {
                  backgroundColor: "#FFF",
                },
                ...buttonStyle,
              }}
              className={classes.overlay}
              color="primary"
              component="span"
            >
              {liked ? <Favorite style={{ color: "red" }} /> : <Favorite />}
            </IconButton>
            {items &&
              items.map((item, i) => (
                <div
                  style={{
                    position: "absolute",
                    left: `${(item.xRatio + item.widthRatio / 2) * width}px`,
                    top: `${(item.yRatio + item.heightRatio / 2) * height}px`,
                    transform: "translate(-50%, -50%)",
                    ...buttonStyle,
                  }}
                  key={i}
                >
                  <LightTooltip title={item.label}>
                    <IconButton
                      style={{
                        color: "white",
                        backgroundColor: "rgba(255, 255, 255, 0.2)",
                      }}
                      onMouseDown={(event) => event.stopPropagation()}
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        handleChipClick(item);
                      }}
                    >
                      <FiberManualRecordIcon />
                    </IconButton>
                  </LightTooltip>
                </div>
              ))}
          </div>
        </CardActionArea>
        {/*</CardActionArea>*/}
        {cardContent}
        {cardActions}
      </Card>
    );
  }
};

export default HoverCard;

{
  /*
                <Chip
                  label={item.label}
                  style={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                  onMouseDown={(event) => event.stopPropagation()}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    handleChipClick(item);
                  }}
                />
*/
}

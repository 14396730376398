import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import ItemsCard from "./ItemsCard";
import MultiLineGridList from "./MultiLineGridList";
import { ProductsSingleLineGridList } from "./SingleLineGridList";
import Skeleton from "@material-ui/lab/Skeleton";
// TODO restart at index zero
const SimilarProductsSingleLine = ({ product_id, title }) => {
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);
  const cursor = 0;
  const limit = 20;

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  useEffect(() => {
    fetch("/api/products?product-id=" + product_id + "&size=" + limit)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.items) {
            setItems(result.items);
          }
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setError(error);
        }
      );
  }, [product_id]);

  // if (error) {
  //   return <div> Error: {error.message} </div>;
  // } else {
  return (
    <div>
      <h2> {title} </h2>
      {items.length == 0 && (
        <div style={{ display: "flex" }}>
          <Skeleton
            variant="rect"
            width={600}
            height={300}
            style={{ margin: "20px" }}
          />
          <Skeleton
            variant="rect"
            width={600}
            height={300}
            style={{ margin: "20px" }}
          />
          <Skeleton
            variant="rect"
            width={600}
            height={300}
            style={{ margin: "20px" }}
          />
        </div>
      )}
      {items.length > 0 && (
        <div>
          <ProductsSingleLineGridList key={product_id} tileData={items} />
        </div>
      )}
    </div>
  );
  // }
};

export default SimilarProductsSingleLine;

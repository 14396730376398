import React, { useState, useEffect, useCallback } from "react";
import RoomItemsCard from "./RoomItemsCard";
import Skeleton from "@material-ui/lab/Skeleton";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { RoutesMap } from "./Routes";
import { Link as RouterLink } from "react-router-dom";
import ItemsCard from "./ItemsCard";
import RoomCard from "./RoomCard";
import MultiLineGridList from "./MultiLineGridList2";
import SimilarRooms from "./SimilarRooms";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import SkipNextIcon from "@material-ui/icons/SkipNext";
import SimilarProductsSingleLine from "./SimilarProductsSingleLine";
import NestedGridSkeleton from "./NestedGridSkeleton";
import { ContactSupportOutlined } from "@material-ui/icons";
import ProductGallery from "./ProductGallery";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
}));

const Search = ({ match, trigger }) => {
  return (
    <ProductGallery
      key={match.params.query}
      title={`Results for "${match.params.query}"`}
      endpoint={"/api/product-text-query2"}
      matchQuery={`query=${match.params.query}`}
      trigger={trigger}
    />
  );
  // const [error, setError] = useState(null);
  // const [items, setItems] = useState([]);
  // const [query, setQuery] = useState("");
  // const [cursor, setCursor] = React.useState(0);
  // const [hasMore, setHasMore] = React.useState(true);
  // const [isLoaded, setIsLoaded] = useState(false);

  // const [from, setFrom] = React.useState(0);
  // const size = 50;

  // const imageRenderer = useCallback(
  //   ({ index, left, top, key, photo }) => (
  //     <ItemsCard
  //       item={photo}
  //       key={photo.productID}
  //       src={photo.src}
  //       width={photo.width}
  //       height={photo.height}
  //     />
  //   ),
  //   [items]
  // );

  // // Note: the empty deps array [] means
  // // this useEffect will run once
  // // similar to componentDidMount()
  // useEffect(() => {
  //   fetch(
  //     "/api/product-text-query2?query=" +
  //       props.match.params.query +
  //       "&from=" +
  //       0 +
  //       "&size=" +
  //       size
  //   )
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         if (result.items) {
  //           setItems([result.items]);
  //           setFrom(size);
  //           if (result.items.length != size) {
  //             setHasMore(false);
  //           } else {
  //             setHasMore(true);
  //           }
  //         } else {
  //           setItems([]);
  //           setHasMore(false);
  //           setFrom(0);
  //         }
  //         setIsLoaded(true);
  //       },
  //       // Note: it's important to handle errors here
  //       // instead of a catch() block so that we don't swallow
  //       // exceptions from actual bugs in components.
  //       (error) => {
  //         setIsLoaded(true);
  //         setError(error);
  //       }
  //     );
  // }, [props.match.params.query]);

  // const fetchMoreData = () => {
  //   fetch(
  //     "/api/product-text-query2?query=" +
  //       props.match.params.query +
  //       "&from=" +
  //       from +
  //       "&size=" +
  //       size
  //   )
  //     .then((res) => res.json())
  //     .then(
  //       (result) => {
  //         if (result.items) {
  //           setItems(items.concat([result.items]));
  //           setFrom(from + size);

  //           if (result.items.length != size) {
  //             setHasMore(false);
  //           } else {
  //             setHasMore(true);
  //           }
  //         } else {
  //           setItems([]);
  //           setHasMore(false);
  //         }
  //       },
  //       // Note: it's important to handle errors here
  //       // instead of a catch() block so that we don't swallow
  //       // exceptions from actual bugs in components.
  //       (error) => {
  //         setError(error);
  //       }
  //     );
  // };

  // if (error) {
  //   return <div> Error: {error.message} </div>;
  // } else if (!isLoaded) {
  //   return (
  //     <div>
  //       <Skeleton variant="text" width={300} style={{ margin: "20px" }} />
  //       <div style={{ display: "flex" }}>
  //         <Skeleton
  //           variant="rect"
  //           width={600}
  //           height={300}
  //           style={{ margin: "20px" }}
  //         />
  //         <Skeleton
  //           variant="rect"
  //           width={600}
  //           height={300}
  //           style={{ margin: "20px" }}
  //         />
  //         <Skeleton
  //           variant="rect"
  //           width={600}
  //           height={300}
  //           style={{ margin: "20px" }}
  //         />
  //       </div>
  //       <div style={{ display: "flex" }}>
  //         <Skeleton
  //           variant="rect"
  //           width={600}
  //           height={300}
  //           style={{ margin: "20px" }}
  //         />
  //         <Skeleton
  //           variant="rect"
  //           width={600}
  //           height={300}
  //           style={{ margin: "20px" }}
  //         />
  //         <Skeleton
  //           variant="rect"
  //           width={600}
  //           height={300}
  //           style={{ margin: "20px" }}
  //         />
  //       </div>
  //       <div style={{ display: "flex" }}>
  //         <Skeleton
  //           variant="rect"
  //           width={600}
  //           height={300}
  //           style={{ margin: "20px" }}
  //         />
  //         <Skeleton
  //           variant="rect"
  //           width={600}
  //           height={300}
  //           style={{ margin: "20px" }}
  //         />
  //         <Skeleton
  //           variant="rect"
  //           width={600}
  //           height={300}
  //           style={{ margin: "20px" }}
  //         />
  //       </div>
  //     </div>
  //   );
  // } else {
  //   return (
  //     <div>
  //       <h2> {`Results for "${props.match.params.query}"`} </h2>
  //       <MultiLineGridList
  //         // key = {props.match.params.query}
  //         // title={`Results for "${props.match.params.query}"`}
  //         srcs={items}
  //         fetchMoreData={fetchMoreData}
  //         imageRenderer={imageRenderer}
  //         hasMore={hasMore}
  //       />
  //     </div>
  //   );
  // }
};

export default Search;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link as RouterLink } from "react-router-dom";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import HomeIcon from "@material-ui/icons/Home";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  chip: {
    // margin: theme.spacing(0.5),
    fontSize: "16px",
    "&&:focus": {
      backgroundColor: "white",
    },
    "&&:hover": {
      borderColor: "black",
      backgroundColor: "white",
    },
  },
}));

const CustomizedBreadcrumbs = ({ pathnames, onClick }) => {
  const classes = useStyles();
  const defaultPath = ["Categories"].concat(pathnames);
  return (
    <div>
      <Paper
        component="ul"
        style={{ listStyle: "none", padding: "10px", margin: 0 }}
        elevation={0}
      >
        {defaultPath.map((value, index) => {
          const last = index === pathnames.length;
          const paths = defaultPath.slice(1, index + 1);
          const to = `/${paths.join("/")}`;

          return last ? (
            <Typography variant="h5" style={{ padding: "10px" }}>
              {defaultPath[pathnames.length]}
            </Typography>
          ) : (
            <li style={{ padding: "10px" }}>
              <Chip
                variant="outlined"
                className={classes.chip}
                key={to}
                label={value}
                onClick={onClick(paths)}
              />
            </li>
          );
        })}
      </Paper>
    </div>
  );
};

const productCategoryConfig = {
  "Baby & Kids": { "Baby & Toddler Toys": [] },
  Furniture: {
    "Baby & Kids' Furniture": ["Kids' Furniture"],
    "Bar & Game Room Furniture": [
      "Bar Cabinets",
      "Bar Furniture",
      "Bar Stools",
      "Directors' Chairs",
      "Game Tables",
      "Gaming Chairs",
    ],
    "Bathroom Furniture": [
      "Bathroom Furniture Sets",
      "Bathroom Mirrors",
      "Bathroom Shelves",
      "Bathroom Storage Cabinets",
    ],
    "Bedroom Furniture": [
      "Armoires & Wardrobes",
      "Bed & Bed Frame Accessories",
      "Bed Frames",
      "Bedroom Benches",
      "Bedroom Sets",
      "Bedroom Vanities",
      "Beds",
      "Box Springs",
      "Dressers",
      "Headboards",
      "Mattresses",
      "Nightstands",
      "Vanity Stools",
    ],
    "Entryway & Accent Furniture": [
      "Accent Cabinets",
      "Accent Chairs",
      "Accent & End Tables",
      "Accent Shelves",
      "Coat Racks",
      "Console Tables",
      "Entryway Benches",
      "Room Dividers",
      "Umbrella Stands",
    ],
    "Furniture Parts & Accessories": [
      "Chair & Sofa Cushions",
      "Hanging Chair Accessories",
      "Table Legs",
      "Table Tops",
    ],
    "Kids' Furniture": [
      "Kids' Bed",
      "Kids' Chairs",
      "Kids' Desks",
      "Kids' Sofas",
      "Kids' Table",
      "Kids' Table & Chair Sets",
      "Nursery & Kids' Wallpaper",
    ],
    "Kitchen & Dining Furniture": [
      "Bakers' Racks",
      "Buffets & Sideboards",
      "China Cabinets & Hutches",
      "Dining Benches",
      "Dining Chairs",
      "Dining Room Sets",
      "Kitchen Carts & Islands",
      "Kitchen & Dining Tables",
      "Pub & Bistro Sets",
    ],
    "Living Room Furniture": [
      "Decorative Racks & Ladders",
      "Entertainment Centers & TV Stands",
      "Futons & Sleepers",
      "Living Room Chairs",
      "Living Room Sets",
      "Living Room Tables",
      "Ottomans & Footstools",
      "Sofas & Sectionals",
    ],
    "Nursery Furniture": [
      "Bassinets",
      "Changing Tables",
      "Crib Conversion Kits",
      "Crib Mattresses",
      "Cribs",
      "Moses Baskets",
      "Nursery Rockers & Gliders",
    ],
    "Office Furniture": [
      "AV Carts",
      "Bookshelves",
      "Conference Room Tables",
      "Desk Hutches",
      "Desk Parts & Add-ons",
      "Desks",
      "Folding Chairs",
      "Folding Tables",
      "Lecterns & Podiums",
      "Mail & File Carts",
      "Office Chair Mats",
      "Office Chair Parts",
      "Office Chairs",
      "Printer Stands",
      "Reception Seating",
    ],
    "Outdoor Furniture": [
      "Camp Furniture",
      "Hammocks",
      "Outdoor Furniture Covers",
      "Outdoor Seating",
      "Outdoor Storage Boxes",
      "Outdoor Tables",
      "Patio Chair & Sofa Cushions",
      "Patio Furniture Sets",
      "Patio Umbrella Accessories",
      "Patio Umbrellas",
    ],
    "Storage & Organization": [
      "Bathroom Vanities",
      "File Cabinets",
      "Household Storage Shelves",
      "Media Storage",
      "Medicine Cabinets",
      "Storage Benches",
      "Storage Cabinets",
      "Storage Chests",
      "Storage & Utility Cabinets",
      "Under-Bed Storage",
      "Utility Carts",
    ],
  },
  "Home Decor": {
    "Artificial & Dried Flowers & Greenery": [
      "Artificial Flower Arrangements",
      "Artificial Plants & Bushes",
      "Artificial Trees",
      "Dried Flowers & Greenery",
      "Lighted Artificial Plants & Flowers",
      "Wreaths & Garlands",
    ],
    "Art Sculptures": [],
    "Candles & Accessories": ["Candle Holders", "Candles"],
    Clocks: ["Alarm Clocks", "Mantel & Tabletop Clocks", "Wall Clocks"],
    "Decorative Signs": [],
    "Frames & Photo Albums": [
      "Photo Albums",
      "Photo Storage Boxes",
      "Picture Frames",
      "Poster Frames",
    ],
    "Holiday & Seasonal Decor": [
      "Christmas & Holiday Decor",
      "Easter Decor",
      "Halloween Decor",
      "Seasonal Storage & Organization",
      "Thanksgiving & Fall Decor",
    ],
    "Home Accents": [
      "Bookends",
      "Decorative Baskets",
      "Decorative Bells",
      "Decorative Bottles",
      "Decorative Bowls",
      "Decorative Centerpieces",
      "Decorative Finials",
      "Decorative Globes",
      "Decorative Jars",
      "Decorative Nutcrackers",
      "Decorative Plates",
      "Decorative Plate Stands & Holders",
      "Decorative Trays",
      "Hourglasses",
      "Music Boxes",
      "Refrigerator Magnets",
      "Shadow Boxes",
      "Snowglobes",
      "Stained Glass Panels",
      "Suncatchers",
      "Vase Fillers",
      "Vases",
    ],
    "Home Fragrances": [
      "Candle & Oil Warmers",
      "Incense",
      "Incense Burners",
      "Potpourri",
      "Wax Melts",
    ],
    Mirrors: [
      "Dresser Mirrors",
      "Floor Mirrors",
      "Full Length Mirrors",
      "Vanity Mirrors",
      "Wall Mirrors",
    ],
    "Nursery & Kids' Room Decor": [
      "Growth Charts",
      "Nursery & Kids' Lamps",
      "Nursery & Kids' Picture Frames",
      "Nursery & Kids' Wall Decor",
      "Piggy Banks",
    ],
    "Pillows & Throws": ["Throw Blankets", "Throw Pillows"],
    "Rugs & Doormats": ["Doormats", "Rug Pads", "Rugs"],
    Slipcovers: [
      "Dining Room Chair Covers",
      "Living Room Chair Covers",
      "Ottoman Covers",
      "Sofa & Loveseat Covers",
    ],
    "Statues & Figurines": [],
    "Wall Art & Decor": [
      "Art Prints",
      "Dreamcatchers",
      "Mezuzahs",
      "Paintings",
      "Posters",
      "Tapestries",
      "Wall Art",
      "Wall Decals",
      "Wall Maps",
      "Wall Murals",
      "Wall Sculptures",
    ],
    "Wall Shelves": [
      "Corner Wall Shelves",
      "Floating Wall Shelves",
      "Glass Wall Shelves",
      "Mounted Wall Cubes",
    ],
    "Window Treatments & Hardware": [
      "Blinds",
      "Curtain Rods & Hardware",
      "Curtains",
      "Shades",
      "Valances",
      "Window Decals",
      "Window Films",
    ],
  },
  Lighting: {
    "Lamps & Accent Lighting": [
      "Buffet Lamps",
      "Desk Lamps",
      "Floor Lamps",
      "Lamp Sets",
      "LED Strip Lights",
      "Night Lights",
      "Oil Lamps",
      "Rope Lights",
      "String Lights",
      "Table Lamps",
    ],
    "Lighting Parts & Accessories": ["Lamp Shades"],
  },
};
const ProductCategoryMenu = ({ onClick }) => {
  const classes = useStyles();
  const [path, setPath] = useState([]);
  const [currConfig, setcurrConfig] = useState(productCategoryConfig);

  const handleClick = (node) => (event) => {
    let config = productCategoryConfig;
    path.push(node);

    path.forEach((item) => {
      config = config[item];
    });

    setPath(path);

    setcurrConfig(config);
  };

  const handleClick2 = (paths) => (event) => {
    let config = productCategoryConfig;
    // TODO, is below necessary
    event.preventDefault();
    paths.forEach((item) => {
      config = config[item];
    });

    setPath(paths);

    setcurrConfig(config);
  };

  return (
    <div className={classes.root}>
      <CustomizedBreadcrumbs pathnames={path} onClick={handleClick2} />
      {Array.isArray(currConfig) ? (
        <List component="nav" aria-label="main mailbox folders">
          {currConfig.map((data) => {
            return (
              <div key={data}>
                <ListItem
                  button
                  to={{
                    pathname: `/Products/` + path.join(",") + "," + data,
                  }}
                  component={RouterLink}
                  onClick={onClick}
                >
                  <ListItemText primary={data} style={{ padding: "10px" }} />
                </ListItem>
              </div>
            );
          })}
        </List>
      ) : (
        <List component="nav" aria-label="main mailbox folders">
          {Object.keys(currConfig).map((data) => {
            return (
              <div key={data}>
                {currConfig[data].length === 0 &&
                Array.isArray(currConfig[data]) ? (
                  <ListItem
                    button
                    to={{
                      pathname: `/Products/` + path.join(",") + "," + data,
                    }}
                    component={RouterLink}
                    onClick={onClick}
                  >
                    <ListItemText primary={data} style={{ padding: "10px" }} />
                  </ListItem>
                ) : (
                  <ListItem button onClick={handleClick(data)}>
                    <ListItemText primary={data} style={{ padding: "10px" }} />
                    <ArrowForwardIosIcon style={{ color: "gray" }} />
                  </ListItem>
                )}
              </div>
            );
          })}
        </List>
      )}
    </div>
  );
};

export default ProductCategoryMenu;

import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import RoomItemsCard from "./RoomItemsCard";
import MultiLineGridList from "./MultiLineGridList2";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Filters from "./Filters2";
import Box from "@material-ui/core/Box";
import ItemsCard from "./ItemsCard";
import Slider from "@material-ui/core/Slider";
import { SignalWifi1BarLockSharp } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  paper: {
    borderRadius: 20,
  },
  popper: {
    marginTop: theme.spacing(1.5),
    fontSize: "16px",
  },
  popperRoom: {
    marginTop: theme.spacing(3),
    fontSize: "16px",
    maxWidth: "700px",
    minWidth: "700px",
    maxHeight: "600px",
    minHeight: "600px",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  formButtons: {
    marginTop: theme.spacing(1.5),
  },
  avatar: {
    margin: theme.spacing(1.5),
    height: "100px",
    width: "100px",
  },
  selectedChip: {
    // margin: theme.spacing(0.5),
    fontSize: "16px",
    backgroundColor: "black",
    color: "white",
    "&&:focus": {
      backgroundColor: "black",
    },
    "&&:hover": {
      backgroundColor: "black",
      borderColor: "black",
    },
  },
  notSelectedChip: {
    // margin: theme.spacing(0.5),
    fontSize: "16px",
    "&&:focus": {
      backgroundColor: "white",
    },
    "&&:hover": {
      borderColor: "black",
      backgroundColor: "white",
    },
  },
  roomChip: {
    margin: theme.spacing(1),
    maxHeight: "50px",
    minHeight: "50px",
    fontSize: "24px",
    fontWeight: "bold",
    "&&:focus": {
      backgroundColor: "white",
    },
    "&&:hover": {
      borderColor: "black",
      backgroundColor: "white",
    },
  },
  roomButton: {
    textTransform: "none",
    fontSize: "20px",
    fontWeight: "bold",
    maxWidth: "200px",
    maxHeight: "200px",
    minWidth: "200px",
    minHeight: "200px",
  },
  cardMedia: {
    //   position: "absolute",
    // backgroundColor: "black",
    // display: "flex",
    // opacity: 0.1,
  },
  filterSkeletonRow: {
    display: "flex",
  },
  filterSkeleton: {
    // margin: "4px",
    // padding: "4px",
    borderRadius: "100px",
  },
  filterSkeleton2: {
    // margin: "4px",
    // padding: "4px",
    margin: theme.spacing(0.5),
    borderRadius: "100px",
  },
  slider: {
    display: "flex",
    // margin: "4px",
    // padding: "4px",
    padding: theme.spacing(1),
    color: "black",
  },
  popperSlider: {
    width: "300px",
    marginTop: theme.spacing(1.5),
    fontSize: "16px",
    zIndex: 2,
  },
  popperMargin: {
    padding: theme.spacing(3),
  },
  priceField: {
    marginRight: theme.spacing(2.7),
    marginLeft: theme.spacing(2.7),
    marginTop: theme.spacing(1.5),
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      suffix={other.value >= 1000 ? "+" : ""}
    />
  );
}

const RangeFilter = ({
  open,
  anchorEl,
  minimum,
  maximum,
  maxLimit,
  filters,
  handleApply,
  isLoaded,
}) => {
  const classes = useStyles();
  const [min, setMin] = React.useState({
    numberformat: null,
  });
  const [max, setMax] = React.useState({
    numberformat: null,
  });

  useEffect(() => {
    setMin({
      ...min,
      numberformat: minimum == null ? 0 : minimum,
    });
    setMax({
      ...max,
      numberformat: maximum == null ? maxLimit : maximum,
    });
  }, [minimum, maximum, open]);

  const [focus, setFocus] = React.useState(null);

  const handleChangeSlider = (event, newValue) => {
    setMin({
      ...min,
      numberformat: newValue[0],
    });
    setMax({
      ...max,
      numberformat: newValue[1],
    });
  };

  const handleChangeMin = (event) => {
    if (event.target.value) {
      const value = Number(event.target.value);
      const minValue = value > maxLimit ? maxLimit : value;
      if (minValue > max.numberformat) {
        setFocus("max");
        setMax({
          ...max,
          numberformat: minValue,
        });
        setMin({
          ...min,
          numberformat: max.numberformat,
        });
      } else {
        setFocus(null);
        setMin({
          ...min,
          numberformat: minValue,
        });
      }
    }
  };

  const handleChangeMax = (event) => {
    if (event.target.value) {
      const value = Number(event.target.value);
      const maxValue = value > maxLimit ? maxLimit : value;
      if (maxValue < min.numberformat) {
        setFocus("min");
        setMax({
          ...max,
          numberformat: min.numberformat,
        });
        setMin({
          ...min,
          numberformat: maxValue,
        });
      } else {
        setFocus(null);
        setMax({
          ...max,
          numberformat: maxValue,
        });
      }
    }
  };

  return (
    <Popper
      className={classes.popperSlider}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-start"
      disablePortal={true}
    >
      <Paper className={classes.paper}>
        <div className={classes.popperMargin}>
          <Typography gutterBottom>Select price range:</Typography>
          <div className={classes.slider}>
            <Slider
              value={[min.numberformat, max.numberformat]}
              onChange={handleChangeSlider}
              valueLabelDisplay="off"
              aria-labelledby="range-slider"
              max={maxLimit}
              style={{ color: "black" }}
            />
          </div>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <TextField
              label="Min Price"
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={min.numberformat}
              className={classes.priceField}
              onChange={handleChangeMin}
              inputRef={(input) => focus == "min" && input && input.focus()}
            />
            <TextField
              label="Max Price"
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              value={max.numberformat}
              className={classes.priceField}
              onChange={handleChangeMax}
              inputRef={(input) => focus == "max" && input && input.focus()}
            />
          </div>
          <div
            style={{
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <Button
              className={classes.formButtons}
              onClick={handleApply([null, null])}
            >
              Clear
            </Button>
            <Button
              className={classes.formButtons}
              onClick={handleApply([
                min.numberformat > 0 ? min.numberformat : null,
                max.numberformat < maxLimit ? max.numberformat : null,
              ])}
            >
              Apply
            </Button>
          </div>
        </div>
      </Paper>
    </Popper>
  );
};

export default RangeFilter;

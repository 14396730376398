import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import RoomItemsCard from "./RoomItemsCard";
import MultiLineGridList from "./MultiLineGridList2";
import RangeFilter from "./RangeFilter";
import FilterChip from "./FilterChip";
import Chip from "@material-ui/core/Chip";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Filters from "./Filters2";
import Box from "@material-ui/core/Box";
import ItemsCard from "./ItemsCard";
import Slider from "@material-ui/core/Slider";
import { SignalWifi1BarLockSharp } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import NumberFormat from "react-number-format";
import Badge from "@material-ui/core/Badge";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  paper: {
    borderRadius: 20,
  },
  popper: {
    marginTop: theme.spacing(1.5),
    fontSize: "16px",
  },
  popperRoom: {
    marginTop: theme.spacing(3),
    fontSize: "16px",
    maxWidth: "700px",
    minWidth: "700px",
    maxHeight: "600px",
    minHeight: "600px",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  formButtons: {
    marginTop: theme.spacing(1.5),
  },
  avatar: {
    margin: theme.spacing(1.5),
    height: "100px",
    width: "100px",
  },
  selectedChip: {
    // margin: theme.spacing(0.5),
    fontSize: "16px",
    backgroundColor: "black",
    color: "white",
    "&&:focus": {
      backgroundColor: "black",
    },
    "&&:hover": {
      backgroundColor: "black",
      borderColor: "black",
    },
  },
  notSelectedChip: {
    // margin: theme.spacing(0.5),
    fontSize: "16px",
    "&&:focus": {
      backgroundColor: "white",
    },
    "&&:hover": {
      borderColor: "black",
      backgroundColor: "white",
    },
  },
  roomChip: {
    margin: theme.spacing(1),
    maxHeight: "50px",
    minHeight: "50px",
    fontSize: "24px",
    fontWeight: "bold",
    "&&:focus": {
      backgroundColor: "white",
    },
    "&&:hover": {
      borderColor: "black",
      backgroundColor: "white",
    },
  },
  roomButton: {
    textTransform: "none",
    fontSize: "20px",
    fontWeight: "bold",
    maxWidth: "200px",
    maxHeight: "200px",
    minWidth: "200px",
    minHeight: "200px",
  },
  cardMedia: {
    //   position: "absolute",
    // backgroundColor: "black",
    // display: "flex",
    // opacity: 0.1,
  },
  filterSkeletonRow: {
    display: "flex",
  },
  filterSkeleton: {
    // margin: "4px",
    // padding: "4px",
    borderRadius: "100px",
  },
  filterSkeleton2: {
    // margin: "4px",
    // padding: "4px",
    margin: theme.spacing(0.5),
    borderRadius: "100px",
  },
  slider: {
    display: "flex",
    // margin: "4px",
    // padding: "4px",
    padding: theme.spacing(1),
    color: "black",
  },
  popperSlider: {
    width: "300px",
    marginTop: theme.spacing(1.5),
    fontSize: "16px",
  },
  popperMargin: {
    padding: theme.spacing(3),
  },
  priceField: {
    marginRight: theme.spacing(2.7),
    marginLeft: theme.spacing(2.7),
    marginTop: theme.spacing(1.5),
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "black",
    },
  },
}));

// stop here
const FilterRow = ({
  filters,
  rangeFilter,
  currFilter,
  isLoaded,
  open,
  anchorEl,
  handleClickAway,
  handleFilterChipClick,
  handleFilterChange,
  handleClear,
  handleApply,
  handleRangeApply,
}) => {
  const classes = useStyles();
  return (
    <div>
      {filters.length > 0 ? (
        <Paper component="ul" className={classes.root} elevation={0}>
          <ClickAwayListener onClickAway={handleClickAway}>
            <div style={{ display: "flex" }}>
              {filters.map((data, i) => {
                return (
                  <li
                    key={data.category}
                    style={{ paddingLeft: "8px", paddingRight: "8px" }}
                  >
                    {data.options.some((val) => val.selected === true) ? (
                      <Badge
                        badgeContent={
                          data.options.filter((value) => value.selected).length
                        }
                        color="secondary"
                      >
                        <FilterChip
                          // variant="outlined"
                          key={data.category}
                          isLoaded={isLoaded}
                          category={data.category}
                          currFilter={currFilter}
                          label={data.label}
                          className={classes.selectedChip}
                          skeletonClass={classes.filterSkeleton}
                          onClick={handleFilterChipClick(data.category)}
                        />
                      </Badge>
                    ) : (
                      <Badge>
                        <FilterChip
                          key={data.category}
                          isLoaded={isLoaded}
                          category={data.category}
                          currFilter={currFilter}
                          variant="outlined"
                          label={data.label}
                          className={classes.notSelectedChip}
                          skeletonClass={classes.filterSkeleton}
                          onClick={handleFilterChipClick(data.category)}
                        />
                      </Badge>
                    )}

                    {data.category == currFilter && (
                      <Filters
                        isLoaded={isLoaded}
                        open={open}
                        anchorEl={anchorEl}
                        filters={data.options}
                        categoryIndex={i}
                        handleChange={handleFilterChange}
                        handleClear={handleClear}
                        handleApply={handleApply}
                      />
                    )}
                  </li>
                );
              })}
              {rangeFilter &&
                rangeFilter.map((data, i) => {
                  return (
                    <li
                      key={data.category}
                      style={{ paddingLeft: "8px", paddingRight: "8px" }}
                    >
                      {data.range[0].value || data.range[1].value ? (
                        <FilterChip
                          // variant="outlined"
                          key={data.category}
                          isLoaded={isLoaded}
                          category={data.category}
                          currFilter={currFilter}
                          label={data.category}
                          className={classes.selectedChip}
                          skeletonClass={classes.filterSkeleton}
                          onClick={handleFilterChipClick(data.category)}
                        />
                      ) : (
                        <FilterChip
                          isLoaded={isLoaded}
                          category={data.category}
                          currFilter={currFilter}
                          variant="outlined"
                          label={data.category}
                          className={classes.notSelectedChip}
                          skeletonClass={classes.filterSkeleton}
                          onClick={handleFilterChipClick(data.category)}
                        />
                      )}
                      {data.category == currFilter && (
                        <RangeFilter
                          open={open}
                          anchorEl={anchorEl}
                          minimum={data.range[0].value}
                          maximum={data.range[1].value}
                          maxLimit={1000}
                          handleApply={handleRangeApply}
                        />
                      )}
                    </li>
                  );
                })}
            </div>
          </ClickAwayListener>
        </Paper>
      ) : (
        <div className={classes.filterSkeletonRow}>
          {[...Array(3)].map((_, i) => (
            <Skeleton
              key={i}
              className={classes.filterSkeleton2}
              variant="rect"
              width={60}
              height={30}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterRow;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import ShopTheLook from "./shop_the_look.svg";
import ShopWithConfidence from "./shop_with_confidence.svg";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  image: {
    padding: theme.spacing(2),
    textAlign: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
  },
  text: {
    padding: theme.spacing(2),
    textAlign: "left",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxShadow: "none",
  },
  header: {
    padding: theme.spacing(2),
  },
  description: {
    padding: theme.spacing(2),
  },
  shop_the_look: {
    width: "85%",
    height: "85%",
  },
  shop_with_confidence: {
    width: "85%",
    height: "85%",
  },
  grid: {
    paddingTop: theme.spacing(5),
  },
  button: {
    margin: theme.spacing(1),
    height: "50px",
    width: "160px",
    fontSize: "16px",
  },
}));

function Home() {
  const classes = useStyles();
  const handleClick = (event) => {};
  return (
    <div className={classes.root}>
      <Grid container xs={12} spacing={3} className={classes.grid}>
        <Grid item lg={6} xs={12}>
          <Paper className={classes.image}>
            <img
              src={ShopTheLook}
              alt="logo"
              className={classes.shop_the_look}
            />
          </Paper>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Paper className={classes.text}>
            <Typography variant="h3" className={classes.header}>
              Shop The Look
            </Typography>
            <Typography variant="body1" className={classes.description}>
              Browse our room gallery and shop similar furniture.
            </Typography>
            <Chip
              variant="outline"
              label="Browse Rooms"
              onClick={handleClick}
              to={{
                pathname: `/Rooms/`,
              }}
              component={RouterLink}
              className={classes.button}
            />
          </Paper>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Paper className={classes.image}>
            <img
              src={ShopWithConfidence}
              alt="logo"
              className={classes.shop_with_confidence}
            />
          </Paper>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Paper className={classes.text}>
            <Typography variant="h3" className={classes.header}>
              Shop With Confidence
            </Typography>
            <Typography variant="body1" className={classes.description}>
              Not sure if a product would look good in your rooom? All products
              are paired with room images that may catch your eye.
            </Typography>
            <Chip
              variant="outline"
              label="Browse Products"
              onClick={handleClick}
              to={{
                pathname: `/Products/`,
              }}
              component={RouterLink}
              className={classes.button}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Home;

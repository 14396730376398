import React, { useState, useEffect } from "react";
import RoomItemsCard from "./RoomItemsCard";
import Skeleton from "@material-ui/lab/Skeleton";
import SimilarProducts from "./SimilarProducts";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { RoutesMap } from "./Routes";
import { Link as RouterLink } from "react-router-dom";
import HoverCard from "./HoverCard";

const RoomItems = ({ match, trigger }) => {
  // const [image, setImage] = useState(null);
  const [bboxId, setBboxId] = useState(null);
  const [roomData, setRoomData] = useState(null);

  const handleChipClick = (item) => {
    console.log("clicked", item.bboxId);
    setBboxId(item.bboxId);
  };

  useEffect(() => {
    fetch("/api/room/" + match.params.room_id)
      .then((res) => res.json())
      .then(
        (result) => {
          // const aspectRatio = result.width / result.height;
          // if (aspectRatio < 1) {
          //   result.height = 400;
          //   result.width = result.height * aspectRatio;
          // } else {
          //   result.width = 400;
          //   result.height = result.width / aspectRatio;
          // }
          setRoomData(result);
        },
        (error) => {}
      );
  }, [match.params.room_id]);

  return (
    <div>
      {roomData && (
        <Breadcrumbs aria-label="breadcrumb" style={{ padding: "10px" }}>
          {/* TODO update link */}
          <Link component={RouterLink} to="/Rooms" color="inherit">
            Rooms
          </Link>
          <Typography color="textPrimary">Items</Typography>
        </Breadcrumbs>
      )}
      {!roomData && <HoverCard width={400} height={400} />}
      {roomData && (
        <RoomItemsCard
          imageId={match.params.room_id}
          src={roomData.src}
          width={roomData.width}
          height={roomData.height}
          handleChipClick={handleChipClick}
          bbox_id={bboxId}
          popOver={true}
          trigger={trigger}
        />
      )}
      {bboxId && (
        <SimilarProducts
          key={bboxId}
          bbox_id={bboxId}
          title="Similar Items"
          trigger={trigger}
        />
      )}
    </div>
  );
};

export default RoomItems;

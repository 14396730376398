import React from "react";
import { Link as RouterLink } from "react-router-dom";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardActions from "@material-ui/core/CardActions";
import HoverCard from "./HoverCard";

const ItemsCard = ({ item }) => {
  return (
    <RouterLink
      to={{ pathname: `/Product/` + `${item.productID}` }}
      // to={{ pathname: `${item.productUrl}` }}
      style={{ textDecoration: "none" }}
      // target="_blank"
    >
      <HoverCard
        cardContent={
          <CardContent style={{ padding: "0px", width: item.width }}>
            <div style={{ padding: "10px" }}>
              <Typography
                display="inline"
                gutterBottom
                variant="h8"
                component="h4"
              >
                {"$ "}
              </Typography>
              <Typography
                display="inline"
                gutterBottom
                variant="h5"
                component="h2"
              >
                {item.price}
              </Typography>
              <Typography variant="body1" component="h2">
                {item.productName}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {item.merchant}
              </Typography>
            </div>
          </CardContent>
        }
        cardActions={
          <CardActions>
            <Button size="small" color="primary">
              Share
            </Button>
          </CardActions>
        }
        src={item.src}
        width={item.width}
        height={item.height}
      />
    </RouterLink>
  );
};

export default ItemsCard;

import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import ItemsCard from "./ItemsCard";
import MultiLineGridList from "./MultiLineGridList2";
import ProductGallery from "./ProductGallery";

// const SimilarProducts = ({ bbox_id, title }) => {
//   const [error, setError] = useState(null);
//   const [items, setItems] = useState([]);
//   const [hasMore, setHasMore] = React.useState(true);
//   const [from, setFrom] = React.useState(0);
//   const size = 50;
//   const [isLoaded, setIsLoaded] = useState(false);

//   const imageRenderer = useCallback(
//     ({ index, left, top, key, photo }) => (
//       <ItemsCard
//         item={photo}
//         //        key={key}
//         key={photo.productID}
//         src={photo.src}
//         width={photo.width}
//         height={photo.height}
//       />
//     ),
//     [items]
//   );

//   // Note: the empty deps array [] means
//   // this useEffect will run once
//   // similar to componentDidMount()
//   useEffect(() => {
//     fetch(
//       "/api/bbox-products?bbox-id=" + bbox_id + "&from=" + 0 + "&size=" + size
//     )
//       .then((res) => res.json())
//       .then(
//         (result) => {
//           if (result.items) {
//             setItems([result.items]);
//             setFrom(size);

//             if (result.items.length != size) {
//               setHasMore(false);
//             } else {
//               setHasMore(true);
//             }
//           } else {
//             setItems([]);
//             setHasMore(false);
//             setFrom(0);
//           }
//           setIsLoaded(true);
//         },
//         // Note: it's important to handle errors here
//         // instead of a catch() block so that we don't swallow
//         // exceptions from actual bugs in components.
//         (error) => {
//           setError(error);
//         }
//       );
//   }, [bbox_id]);

//   const fetchMoreData = () => {
//     fetch(
//       "/api/bbox-products?bbox-id=" +
//         bbox_id +
//         "&from=" +
//         from +
//         "&size=" +
//         size
//     )
//       .then((res) => res.json())
//       .then(
//         (result) => {
//           if (result.items) {
//             setItems(items.concat([result.items]));
//           }

//           setFrom(from + size);

//           if (result.items.length != size) {
//             setHasMore(false);
//           } else {
//             setHasMore(true);
//           }
//         },
//         // Note: it's important to handle errors here
//         // instead of a catch() block so that we don't swallow
//         // exceptions from actual bugs in components.
//         (error) => {
//           setError(error);
//         }
//       );
//   };

//   if (error) {
//     return <div> Error: {error.message} </div>;
//   } else {
//     return (
//       <MultiLineGridList
//         title={title}
//         srcs={items}
//         fetchMoreData={fetchMoreData}
//         imageRenderer={imageRenderer}
//         hasMore={hasMore}
//       />
//     );
//   }
// };

const SimilarProducts = ({ bbox_id, title, trigger }) => {
  return (
    <ProductGallery
      key={bbox_id}
      title={title}
      endpoint={"/api/bbox-products"}
      bboxId={`bbox-id=${bbox_id}`}
      trigger={trigger}
    />
  );
};

export default SimilarProducts;

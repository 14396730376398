import "./App.css";
import HsAppBar from "./HsAppBar";
import Container from "@material-ui/core/Container";
import Routes from "./Routes";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import React from "react";

function App() {
  const trigger = useScrollTrigger();
  const [searchText, setSearchText] = React.useState("");

  // const setSearchText = (text) => {
  //   setSearchText(text);
  // };

  return (
    <Container maxWidth="lg">
      <HsAppBar
        trigger={trigger}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <Routes trigger={trigger} setSearchText={setSearchText} />
    </Container>
  );
}

export default App;

import "./App.css";
import logo from "./logo.svg";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Grid from "@material-ui/core/Grid";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import CardMedia from "@material-ui/core/CardMedia";
import Card from "@material-ui/core/Card";
import Drawer from "@material-ui/core/Drawer";
import React from "react";
import Chip from "@material-ui/core/Chip";
import CardActionArea from "@material-ui/core/CardActionArea";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useEffect, useState } from "react";
import Slide from "@material-ui/core/Slide";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Box from "@material-ui/core/Box";

import ProductCategoryMenu from "./ProductCategoryMenu";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: "75%",
    height: "75%",
    float: "left",
    // margin: theme.spacing(3),
    // alignItems: "left",
    // justifyContent: "left",
    // display: "flex",
    // position: "absolute",
  },
  logosmall: {
    width: "40%",
    height: "40%",
    float: "left",
    // margin: theme.spacing(3),
    // alignItems: "left",
    // justifyContent: "left",
    // display: "flex",
    // position: "absolute",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.black, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.black, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  button: {
    border: 0,
  },
  link: {
    "& > * + *": {
      marginLeft: theme.spacing(4),
    },
  },
  roomName: {
    justifyContent: "center",
    color: "black",
    display: "flex",
    width: "100%",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
  buttonGroup: {
    float: "right",
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  toolbarWrapper: {
    margin: "auto",
    maxWidth: "1270px",
    width: "100%",
    "& .MuiToolbar-gutters": {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
  appBar: {
    background: "white",
    zIndex: 1,
  },
}));

function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MenuIcon
          style={{
            fill: "black",
            fontSize: "1.4em",
            float: "right",
            display: "flex",
          }}
        />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component={RoomMenu} onClick={handleClose}>
          Rooms
        </MenuItem>
        <MenuItem component={ProductFilter} onClick={handleClose}>
          Products
        </MenuItem>
        <MenuItem component={AccountMenu} onClick={handleClose}>
          Account
        </MenuItem>
      </Menu>
    </div>
  );
}

const RoomFilter = ({ rooms }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (isOpen) => () => {
    setOpen(isOpen);
  };

  return (
    <div>
      <Button onClick={toggleDrawer(true)}>Rooms</Button>
      <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
        <Typography
          variant="h4"
          style={{ padding: "10px", fontWeight: "bold" }}
        >
          Rooms
        </Typography>
        <Typography variant="h7" style={{ padding: "10px", color: "gray" }}>
          Pick a room for design inspirations
        </Typography>
        <Grid container style={{ maxWidth: "600px" }}>
          {rooms.map((data, i) => {
            return (
              <Grid key={i} item lg={6}>
                <Card style={{ margin: "10px" }} elevation={0}>
                  <RouterLink
                    to={{
                      pathname: `/Rooms/` + data.category,
                    }}
                    component={CardActionArea}
                  >
                    <CardMedia
                      component="img"
                      alt={data.title}
                      height="200"
                      // width="200"
                      image={data.image}
                      title={data.title}
                      className={classes.cardMedia}
                    />
                    <Typography
                      variant="h5"
                      component="h2"
                      className={classes.roomName}
                    >
                      {data.title}
                    </Typography>
                  </RouterLink>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Drawer>
    </div>
  );
};

const RoomMenu = ({ onClick }) => {
  const handleClick = () => {
    onClick();
  };
  return (
    <div style={{ display: "flex" }}>
      <Button
        onClick={handleClick}
        component={RouterLink}
        to={{
          pathname: `/Rooms/`,
        }}
        fullWidth
      >
        Rooms
      </Button>
    </div>
  );
};

const AccountMenu = ({ onClick }) => {
  const handleClick = () => {
    onClick();
  };
  return (
    <div style={{ display: "flex" }}>
      <Button
        onClick={handleClick}
        component={RouterLink}
        to={{
          pathname: `/`,
        }}
        fullWidth
      >
        Account
      </Button>
    </div>
  );
};

const ProductFilter = ({ onClick }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const closeDrawer = () => {
    setOpen(false);
  };

  const openDrawer = () => {
    setOpen(true);
    onClick();
  };

  return (
    <div style={{ display: "flex" }}>
      <Button onClick={openDrawer} fullWidth>
        Products
      </Button>
      <Drawer anchor="left" open={open} onClose={closeDrawer}>
        <Typography
          variant="h4"
          style={{ padding: "10px", fontWeight: "bold" }}
        >
          Products
        </Typography>
        {/* <Typography variant="h7" style={{ padding: "10px", color: "gray" }}>
          Pick a category
        </Typography> */}
        <ProductCategoryMenu onClick={closeDrawer} />
      </Drawer>
    </div>
  );
};

const HsAppBar2 = ({ searchText, setSearchText }) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const history = useHistory();

  function handleSubmit(event) {
    event.preventDefault();
    history.push("/search/" + encodeURIComponent(searchQuery));
  }

  function handleChange(event) {
    // event.preventDefault();
    // history.push('/goodbye');
    setSearchQuery(event.target.value);
    setSearchText(event.target.value);
    console.log(event.target.value);
  }

  const classes = useStyles();
  return (
    <div>
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <div className={classes.toolbarWrapper}>
          <Toolbar>
            <Grid
              container
              spacing={1}
              alignItems="center"
              justify="center"
              align="center"
            >
              <Hidden smDown>
                <Grid item lg={3} md={3}>
                  <Paper
                    component={RouterLink}
                    to={{
                      pathname: `/`,
                    }}
                    style={{ boxShadow: "none" }}
                  >
                    <img src={logo} alt="logo" className={classes.logo} />
                  </Paper>
                </Grid>
              </Hidden>
              <Hidden mdUp>
                <Grid item sm={10} xs={10}>
                  <Paper
                    component={RouterLink}
                    to={{
                      pathname: `/`,
                    }}
                    style={{ boxShadow: "none" }}
                  >
                    <img src={logo} alt="logo" className={classes.logosmall} />
                  </Paper>
                </Grid>
                <Grid item sm={2} xs={2}>
                  <SimpleMenu />
                </Grid>
              </Hidden>
              <Grid item lg={5} md={5} sm={12} xs={12}>
                <Paper
                  component="form"
                  className={classes.search}
                  onSubmit={handleSubmit}
                >
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    value={searchText}
                    placeholder="Search"
                    fullWidth="true"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{
                      "aria-label": "search",
                    }}
                    onChange={handleChange}
                  />
                </Paper>
              </Grid>
              <Grid item lg={4} md={4}>
                <Hidden smDown>
                  <div className={classes.buttonGroup}>
                    <RoomMenu onClick={() => {}} />
                    <ProductFilter onClick={() => {}} />
                    <IconButton>
                      <AccountCircle
                        style={{ fill: "black", fontSize: "1.4em" }}
                      />
                    </IconButton>
                  </div>
                </Hidden>
              </Grid>
            </Grid>
          </Toolbar>
        </div>
      </AppBar>
      <Hidden smDown>
        <Toolbar />
      </Hidden>
      <Hidden mdUp>
        <Toolbar />
        <Toolbar />
      </Hidden>
    </div>
  );
};

const HideOnScroll = ({ children, trigger }) => {
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const HsAppBar = ({ trigger, searchText, setSearchText }) => {
  return (
    <HideOnScroll
      trigger={trigger}
      children={
        <div>
          <HsAppBar2 searchText={searchText} setSearchText={setSearchText} />
        </div>
      }
    ></HideOnScroll>
  );
};

export default HsAppBar;
